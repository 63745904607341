import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Home from './routes/Home.vue'
import Catalog from './routes/Catalog.vue'


const routes = [
  { path: '/', component: Home },
  { path: '/catalog', component: Catalog },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const app = createApp(App)
app.use(router)
app.mount('#app')
